<template>
    <el-config-provider :locale="locale">
        <router-view />
    </el-config-provider>
</template>

<script>
import { getCurrentInstance, onMounted, reactive } from "vue";
import { ElConfigProvider } from "element-plus";
import { useStore } from "vuex";
import zhCn from "element-plus/lib/locale/lang/zh-cn";
let locale = zhCn;
export default {
    name: "App",

    components: {
        ElConfigProvider
    },
    setup() {
        const store = useStore();
        const { proxy } = getCurrentInstance();
        const configInfo = reactive({ data: {} });
        // 获取系统配置信息
        const getSystemConfigInfo = () => {
            proxy
                .$get("api/shopp/setting/getSettings", {
                    names: "main_site_logo,main_user_center_logo,main_user_logon_bg,main_user_register_bg,pc_home_bottom_adv,main_user_forget_password_bg,basic_site_name,basic_site_icp,basic_site_copyright,basic_site_technical_support,platform_customer_service_name,platform_customer_service_logo"
                })
                .then((res) => {
                    if (res.code == 200) {
                        res.data.map((item) => {
                            configInfo.data[item.code] = item.value;
                        });
                        store.commit("updateConfigInfo", configInfo.data);
                    }
                });
        };

        onMounted(() => {
            getSystemConfigInfo();
        });
        return {
            locale
        };
    }
};
</script>

<style lang="scss">
@import "./style/reset.scss";
@import "./style/base.scss";
//放大镜位置
.mouse-cover-canvas {
    position: absolute;
    top: 173px !important;
    left: 740px !important;
}
</style>
