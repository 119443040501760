/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2022-10-10 16:38:00
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-03-27 15:20:18
 * @FilePath: /duomengde_pc/src/utils/config.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/**
 *  项目的配置文件
 */
//测试环境
// export const chatUrl = "ws://8.130.113.152:8569"; //测试客服地址
// export const mUrl = "http://8.130.113.152:8023/"; //测试移动端网页地址
// export const apiUrl = "http://8.130.113.152:8567/"; //测试接口请求地址
// export const apiUrlSub = "http://8.130.113.152:8504/"; //测试接口请求地址（打通供应链修改接口）http://8.130.113.152:8504/
// export const uscUrl = "http://8.130.113.152:8501/"; //测试接口请求地址用户中心8501
// export const apiUrlPay = "http://8.130.113.152:8503/"; //测试接口请求地址支付中心接口8503
// export const shoppUrl = " http://8.130.113.152:8504/"; //测试接口请求地址shopp项目的走 辉哥本地



//生产环境
export const chatUrl = "wss://imapi.dashichain.com"; //客服地址
export const mUrl = "https://h5.dashichain.com/"; //移动端网页地址
export const apiUrl = "https://api.dashichain.com/"; //接口请求地址
export const apiUrlSub = "https://chain.dashichain.com/"; //接口请求地址8504
export const apiUrlPay = "https://chain.dashichain.com/"; //请求地址支付中心接口8503
export const uscUrl = "https://chain.dashichain.com/"; //请求地址用户中心8501
export const shoppUrl = "https://chain.dashichain.com/"; //请求地址shopp项目的8504

const domain = window.location.hostname; //* 获取当前页面域名
const port = window.location.port; //* 获取当前页面端口
console.log(domain,port);
const platformVal = domain == "jc.dmdsy.com" || port == "8020" ? 2 : 1; //* 1-大石工采，2-大石集采jc.dmdsy.com  www.dashichain.com
console.log( platformVal);
//const platformVal = 1; //* 1-大石工采，2-大石集采
export const curLang = "zh"; //当前语言,zh:中文，若为其他语言，需要对应/src/assets/language下面的文件名
export const title = `${platformVal == 1 ? "大石工采" : "大石集采"}—工矿企业数字化采购平台`; //浏览器顶部title
export const gdKey = "a5befc73347e0a186eee11d91cfd4435"; //高德web-js key
export const gdSecurityCode = "3992b42e52ec73c89b2ff54038304ea4"; //高德web-js key
export const statShowDebug = false; //是否开启统计的调试
export const platform = platformVal;

/** copyright *** slodon *** version-v3.9 *** date-2022-03-25 ***主版本v3.9**/
